@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&display=swap');
@import url(https://cdnjs.cloudflare.com/ajax/libs/remixicon/3.3.0/remixicon.css);

@import "~ag-grid-community/dist/styles/ag-grid.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";


// Codemirror
@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/hint/show-hint.css';
@import '~codemirror/addon/display/fullscreen.css';

@import '~codemirror/addon/lint/lint.css';
//codemirror themes
@import '~codemirror//theme/dracula.css';
//codemirror search
@import '~codemirror/addon/dialog/dialog.css';
@import '~codemirror/addon/search/matchesonscrollbar.css';
// Theme
@import 'themes';
@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
};

*{  
  font-family: Inter, sans-serif !important;
}
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body{
  margin: 0px;
  font-size: 0.813rem !important;
  color: var(--simplify-gray-900);
}
.text-blue{
  color: #2D64BC !important;
}

/*full width of dialog*/
.full-width-dialog .mat-dialog-container {
  max-width: 100vw !important;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
  border-radius: 0;
}

.group-dialog-container .mat-dialog-container {
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


// UAI Update

:root {

  /* Colors: */
  --gray-90: #212529;
  --gray-80: #343A40;
  --gray-10: #F8F9FA;
  --gray-20: #E9ECEF;
  --gray-30: #DEE2E6;
  --gray-70: #495057;
  --gray-40: #CED4DA;
  --gray-60: #6C757D;
  --gray-50: #ADB5BD;
  --unnamed-color-3a4550: #3A4550;
  --unnamed-color-000000: #000000;
  --unnamed-color-919191: #919191;
  
  /* Font/text values */
  --unnamed-font-family-inter: Inter;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-600: 600px;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-32: 32px;
  --unnamed-font-size-18-719999313354492: 18.719999313354492px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-21: 21px;
  --unnamed-line-spacing-23: 23px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-25: 25px;
  --unnamed-line-spacing-27: 27px;
  --unnamed-line-spacing-40: 40px;
  --unnamed-line-spacing-48: 48px;
  --unnamed-text-transform-uppercase: uppercase;

  // 


  --simplify-border-color-translucent: #AFB9C5;

  --simplify-border-radius-pill: 8px;

  --white-color:#ffffff;    
    --dark-color:#000000;   
    --text-default:#001A43; 
    --primary-color:#228B22;
    --secondary-color:#8C92AC;
    --warning-color:#FFA07A;
    --danger-color: #F28B7C;
    --info-color: #63D1C4;
    --success-color:#66BD50;
    --blue-color:#87CEEB;
    --light-color:#F4F9FD;
    --code-error:#570000;

    --alert-danger:#F27260;
    --alert-success:#66BD50;
    --alert-info:#C3C9D0;
    --alert-warning:#EBBC46;
    --alert-danger-light:#FFF1F1;
    --alert-success-light:#F2FDF6;

    --heading-color: #66BD50;
    --border-color:#E3E9F0;    

    --gray-100:#EBF0F5;
    --gray-200:#E3E9F0;
    --gray-300:#CFD8E1;
    --gray-400:#AFB9C5;
    --gray-500:#A3B0C0;
    --gray-600:#84919F;
    --gray-700:#5B6874;
    --gray-800:#2F3943;
    --gray-900:#1D2939;
    
    --rgb-white-colo: 255, 255, 255;
    --rgb-dark-color:0, 0, 0;
    --rgb-text-default:0, 25, 66; 
    --rgb-primary-color:34, 139, 34;
    --rgb-secondary-color:140, 146, 172;
    --rgb-warning-color:255, 160, 122;
    --rgb-danger-color:242, 139, 124;
    --rgb-info-color: 99, 209, 196;
    --rgb-success-color:102, 189, 80;
    --rgb-blue-color:135, 206, 235;
    --rgb-light-color:244, 249, 253;
    --rgb-code-error:87, 0, 0;

    --rgb-alert-danger:242, 114, 96;
    --rgb-alert-success:102, 189, 80;
    --rgb-alert-info:195, 201, 208;
    --rgb-alert-warning:235, 188, 70;
    --rgb-alert-danger-light:255, 241, 241;
    --rgb-alert-success-light:242, 253, 246;

    --rgb-heading-color: 102, 189, 80;
    --rgb-border-color:227, 233, 240;    

    --rgb-gray-100:235, 240, 245;
    --rgb-gray-200:227, 233, 240;
    --rgb-gray-300:207, 216, 225;
    --rgb-gray-400:175, 185, 197;
    --rgb-gray-500:163, 176, 192;
    --rgb-gray-600:132, 145, 159;
    --rgb-gray-700:91, 104, 116;
    --rgb-gray-800:47, 57, 67;
    --rgb-gray-900:29, 41, 57;
  --simplify-blue: #87CEEB;
  --simplify-indigo: #6610f2;
  --simplify-purple: #6f42c1;
  --simplify-pink: #d63384;
  --simplify-red: #F28B7C;
  --simplify-orange: #FFA07A;
  --simplify-yellow: #ffc107;
  --simplify-green: #228B22;
  --simplify-teal: #66BD50;
  --simplify-cyan: #63D1C4;
  --simplify-black: #000;
  --simplify-white: #fff;
  --simplify-gray: #8C92AC;
  --simplify-gray-dark: #2F3943;
  --simplify-light: #F4F9FD;
  --simplify-darkred: #570000;
  --simplify-dark: #1D252D;
  --simplify-gray-100: #EBF0F5;
  --simplify-gray-200: #E3E9F0;
  --simplify-gray-300: #CFD8E1;
  --simplify-gray-400: #AFB9C5;
  --simplify-gray-500: #A3B0C0;
  --simplify-gray-600: #84919F;
  --simplify-gray-700: #5B6874;
  --simplify-gray-800: #2F3943;
  --simplify-gray-900: #1D2939;
  --simplify-primary: #228B22;
  --simplify-secondary: #8C92AC;
  --simplify-tertiary: #FFA07A;
  --simplify-success: #66BD50;
  --simplify-info: #63D1C4;
  --simplify-warning: #FFA07A;
  --simplify-danger: #F28B7C;
  --simplify-light: #F4F9FD;
  --simplify-dark: #1D252D;
  --simplify-primary-rgb: 34, 139, 34;
  --simplify-secondary-rgb: 140, 146, 172;
  --simplify-tertiary-rgb: 255, 160, 122;
  --simplify-success-rgb: 102, 189, 80;
  --simplify-info-rgb: 99, 209, 196;
  --simplify-warning-rgb: 255, 160, 122;
  --simplify-danger-rgb: 242, 139, 124;
  --simplify-light-rgb: 244, 249, 253;
  --simplify-dark-rgb: 29, 37, 45;
  --simplify-white-rgb: 255, 255, 255;
  --simplify-black-rgb: 0, 0, 0;
  --simplify-body-color-rgb: 0, 26, 67;
  --simplify-body-bg-rgb: 255, 255, 255;
  --simplify-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --simplify-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --simplify-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --simplify-body-font-family: var(--simplify-font-sans-serif);
  --simplify-body-font-size: 1rem;
  --simplify-body-font-weight: 400;
  --simplify-body-line-height: 1.5;
  --simplify-body-color: #001A43;
  --simplify-body-bg: #fff;
  --simplify-border-width: 1px;
  --simplify-border-style: solid;
  --simplify-border-color: #E3E9F0;
  --simplify-border-color-translucent: rgba(0, 0, 0, 0.175);
  --simplify-border-radius: 0.375rem;
  --simplify-border-radius-sm: 0.25rem;
  --simplify-border-radius-lg: 0.5rem;
  --simplify-border-radius-xl: 1rem;
  --simplify-border-radius-2xl: 2rem;
  --simplify-border-radius-pill: 50rem;
  --simplify-link-color: #228B22;
  --simplify-link-hover-color: #1b6f1b;
  --simplify-code-color: #d63384;
  --simplify-highlight-bg: #fff3cd;
  }

  @media (min-width:1600px){
    :host, :root {
      --fs-10: 0.625rem;
      --fs-11: 0.688rem;
      --fs-12: 0.75rem;
      --fs-13: 0.813rem;
      --fs-14: 0.875rem;
      --fs-15: 0.938rem;
      --fs-16: 1rem;
      --fs-17: 1.063;
      --fs-18: 1.125rem;
      --fs-19: 1.188rem;
      --fs-20: 1.25rem;
      --fs-22: 1.375rem;
      --fs-24: 1.5rem;
      --fs-26: 1.625rem;
      --fs-28: 1.75rem;
      --fs-30: 1.875rem;
      --fs-32: 2.125rem;
    }
  }
  @media (min-width:1024px) and (max-width:1600px){
    :host, :root {
      --fs-10: 0.5rem;
      --fs-11: 0.563rem;
      --fs-12: 0.625rem;
      --fs-13: 0.688rem;
      --fs-14: 0.75rem;
      --fs-15: 0.813rem;
      --fs-16: 0.875rem;
      --fs-17: 0.938rem;
      --fs-18: 1rem;
      --fs-19: 1.063;
      --fs-20: 1.125rem;
      --fs-22: 1.188rem;
      --fs-24: 1.25rem;
      --fs-26: 1.375rem;
      --fs-28: 1.5rem;
      --fs-30: 1.625rem;
      --fs-32: 1.75rem;
      --fs-34: 1.875rem;
      --fs-36: 2.125rem;
    }
  }
   
  @media (max-width:1024px){
    :host, :root {
      --fs-10: 0.625rem;
      --fs-11: 0.688rem;
      --fs-12: 0.75rem;
      --fs-13: 0.813rem;
      --fs-14: 0.875rem;
      --fs-15: 0.938rem;
      --fs-16: 1rem;
      --fs-17: 1.063;
      --fs-18: 1.125rem;
      --fs-19: 1.188rem;
      --fs-20: 1.25rem;
      --fs-22: 1.375rem;
      --fs-24: 1.5rem;
      --fs-26: 1.625rem;
      --fs-28: 1.75rem;
      --fs-30: 1.875rem;
      --fs-32: 2.125rem;
    }
  }
  
  /* Character Styles */
  .paragraph-1 {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-27);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-3a4550);
  }
  .overline-text {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-25);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-919191);
  text-transform: var(--unnamed-text-transform-uppercase);
  }
  .h1 {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-32);
  line-height: var(--unnamed-line-spacing-48);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  }
  .h3 {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-18-719999313354492);
  line-height: var(--unnamed-line-spacing-23);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  }
  .h2 {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-40);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  }
  .small-text {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-3a4550);
  }
  .paragraph2-default {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-3a4550);
  }
  .h4 {
  font-family: var(--unnamed-font-family-inter);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  }


.resizable {
  resize: both;
  overflow: auto;
}
//   /* Character Styles */
//   .paragraph-1 {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-normal);
//   font-size: var(--unnamed-font-size-16);
//   line-height: var(--unnamed-line-spacing-27);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-3a4550);
//   }
//   .overline-text {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-medium);
//   font-size: var(--unnamed-font-size-12);
//   line-height: var(--unnamed-line-spacing-25);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-919191);
//   text-transform: var(--unnamed-text-transform-uppercase);
//   }
//   .h1 {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-600);
//   font-size: var(--unnamed-font-size-32);
//   line-height: var(--unnamed-line-spacing-48);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-000000);
//   }
//   .h3 {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-medium);
//   font-size: var(--unnamed-font-size-18-719999313354492);
//   line-height: var(--unnamed-line-spacing-23);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-000000);
//   }
//   .h2 {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-medium);
//   font-size: var(--unnamed-font-size-24);
//   line-height: var(--unnamed-line-spacing-40);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-000000);
//   }
//   .small-text {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-normal);
//   font-size: var(--unnamed-font-size-12);
//   line-height: var(--unnamed-line-spacing-21);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-3a4550);
//   }
//   .paragraph2-default {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-normal);
//   font-size: var(--unnamed-font-size-14);
//   line-height: var(--unnamed-line-spacing-24);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-3a4550);
//   }
//   .h4 {
//   font-family: var(--unnamed-font-family-inter);
//   font-style: var(--unnamed-font-style-normal);
//   font-weight: var(--unnamed-font-weight-medium);
//   font-size: var(--unnamed-font-size-16);
//   line-height: var(--unnamed-line-spacing-20);
//   letter-spacing: var(--unnamed-character-spacing-0);
//   color: var(--unnamed-color-000000);
//   }


// .resizable {
//   resize: both;
//   overflow: auto;
// }


*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: 0.3s background;
}

*::-webkit-scrollbar-thumb, *:hover::-webkit-scrollbar-thumb {
  background: #c2cbdf;
}

*::-ms-webkit-scrollbar-track {
  background: #fff;
}

*::-ms-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: 0.3s background;
}

*::-ms-webkit-scrollbar-thumb,
*:hover::-ms-webkit-scrollbar-thumb {
  background: #c2cbdf;
}

*::-ms-webkit-scrollbar-track {
  background: #fff;
}

.main-sidemenu *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: 0.3s background;
}

// Loader Animation
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-color);
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
