@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/default';

$nb-themes: nb-register-theme((

  // add your variables here like:

  // color-primary-100: #f2f6ff,
  // color-primary-200: #d9e4ff,
  // color-primary-300: #a6c1ff,
  // color-primary-400: #598bff,
  // color-primary-500: #3366ff,
  // color-primary-600: #274bdb,
  // color-primary-700: #1a34b8,
  // color-primary-800: #102694,
  // color-primary-900: #091c7a,

  header-height: 3rem,
  header-padding: 1rem,
  header-text-font-size: 0.7rem,


  header-background-color: #4C7FF0,

  user-picture-box-background-color: #E8EDFD,
  user-initials-text-color: #4C7FF0,
  user-name-text-color: #FFFFFF,

  // button-filled-basic-background-color: #CCEAFA,
  // button-filled-basic-text-color: #4C7FF0,

  // button-filled-primary-background-color: #69C34F,
  // button-filled-primary-text-color: #FFFFFF,

), default, default);


.nb-theme-default nb-layout-header nav
{
    height: 0rem;
    padding: 0rem;
}

